import React, { useState } from "react";
import InsightsPageItem from "../../components/atoms/InsightsPageItem";
import { useStaticQuery, graphql } from "gatsby";
import { useLanguage } from "../../languageContext";

const InsightsContent = () => {
  const currentLanguage = useLanguage();
  const [blogActive, setBlogActive] = useState(false);
  const [newsRoom, setNewsRoom] = useState(false);
  const [caseStudies, setCaseStudies] = useState(true);
  const [linkedInArticles, setLinkedInArticles] = useState(false);

  const changeToBlog = () => {
    setBlogActive(true);
    setNewsRoom(false);
    setCaseStudies(false);
    setLinkedInArticles(false);
  };

  const changeToNewsRoom = () => {
    setBlogActive(false);
    setNewsRoom(true);
    setCaseStudies(false);
    setLinkedInArticles(false);
  };

  const changeToCaseStudies = () => {
    setBlogActive(false);
    setNewsRoom(false);
    setCaseStudies(true);
    setLinkedInArticles(false);
  };
  const changeToLinkedInArticles = () => {
    setBlogActive(false);
    setNewsRoom(false);
    setCaseStudies(false);
    setLinkedInArticles(true);
  };

  const data = useStaticQuery(graphql`
    query InsightsData {
      gcms {
        insightPages(orderBy: createdAt_DESC) {
          image {
            url
          }
          slug
          createdAt
          title
          typeOfInsight
          shortDescription
          date
          thumbnail {
            url
          }
        }
      }
    }
  `);

  return (
    <div className="section m-top-10">
      <div className="container">
        <div className="insighscontent-wrapper">
          <div className="tabs-wrapper">
            <div
              role="button"
              tabIndex={0}
              onClick={changeToCaseStudies}
              className={
                caseStudies
                  ? `tab underline-${currentLanguage ? currentLanguage : "en"}`
                  : "tab"
              }
            >
              Case Studies
            </div>
            <div className="tab-divider"></div>
            <div
              role="button"
              tabIndex={0}
              onClick={changeToNewsRoom}
              className={
                newsRoom
                  ? `tab underline-${currentLanguage ? currentLanguage : "en"}`
                  : "tab"
              }
            >
              News Room
            </div>
            <div className="tab-divider"></div>
            <div
              role="button"
              tabIndex={0}
              onClick={changeToLinkedInArticles}
              className={
                linkedInArticles
                  ? `tab underline-${currentLanguage ? currentLanguage : "en"}`
                  : "tab"
              }
            >
              Articles
            </div>
          </div>
          <hr></hr>
          <div className="content m-top-10">
            {blogActive &&
              data.gcms.insightPages
                .filter((insight) => insight.typeOfInsight === "BLOG")
                .map(
                  ({
                    thumbnail,
                    image,
                    shortDescription,
                    slug,
                    title,
                    date,
                    index,
                  }) => (
                    <InsightsPageItem
                      key={index}
                      heading={title}
                      date={date}
                      para={shortDescription}
                      url={slug}
                      image={image}
                      thumbnail={thumbnail}
                      class="blog-width"
                    ></InsightsPageItem>
                  )
                )}
            {newsRoom &&
              data.gcms.insightPages
                .filter((insight) => insight.typeOfInsight === "NEWS_ROOMS")
                .map(
                  ({
                    thumbnail,
                    image,
                    shortDescription,
                    slug,
                    title,
                    date,
                    index,
                  }) => (
                    <InsightsPageItem
                      key={index}
                      heading={title}
                      date={date}
                      para={shortDescription}
                      url={slug}
                      image={image}
                      thumbnail={thumbnail}
                    ></InsightsPageItem>
                  )
                )}
            {caseStudies &&
              data.gcms.insightPages
                .filter((insight) => insight.typeOfInsight === "CASE_STUDIES")
                .map(
                  ({
                    thumbnail,
                    image,
                    shortDescription,
                    slug,
                    title,
                    date,
                    index,
                  }) => (
                    <InsightsPageItem
                      key={index}
                      heading={title}
                      date={date}
                      para={shortDescription}
                      url={slug}
                      image={image}
                      thumbnail={thumbnail}
                    ></InsightsPageItem>
                  )
                )}
            {linkedInArticles &&
              data.gcms.insightPages
                .filter((insight) => insight.typeOfInsight === "BLOG")
                .map(
                  ({
                    thumbnail,
                    image,
                    shortDescription,
                    slug,
                    title,
                    date,
                    index,
                  }) => (
                    <InsightsPageItem
                      key={index}
                      heading={title}
                      date={date}
                      para={shortDescription}
                      url={slug}
                      image={image}
                      thumbnail={thumbnail}
                    ></InsightsPageItem>
                  )
                )}
          </div>
          <br></br>
        </div>
      </div>
    </div>
  );
};

export default InsightsContent;
